import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

import { useShopStore } from '~/domains';

const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      width: '32.8rem',
      margin: 0,
    },
  },
  dialogWrap: {
    padding: `${theme.spacing(3)}rem ${theme.spacing(2.5)}rem ${theme.spacing(4)}rem`,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: `${theme.spacing(2)}rem`,
  },
  title: {
    fontSize: '1.6rem',
    color: theme.palette.grey[900],
    fontWeight: 500,
    marginRight: 'auto',
  },
  close: {
    height: '2.4rem',
    marginLeft: 'auto',
  },
}));

const ContentDialog: React.FC = observer(() => {
  const {
    contentDialog: {
      isVisible,
      title,
      Contents,
      close,
    },
  } = useShopStore();

  const classes = useStyle();
  const handleClickClose = () => close();

  if (!isVisible) return null;

  return (
    <Dialog className={classes.root} open={isVisible} onBackdropClick={handleClickClose}>
      <Box className={classes.dialogWrap}>
        <Box className={classes.header}>
          {title && (
            <Typography className={classes.title}>{title}</Typography>
          )}
          <button className={classes.close} onClick={handleClickClose}><Close /></button>
        </Box>
        <Contents />
      </Box>
    </Dialog>
  );
});

export default ContentDialog;
