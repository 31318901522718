import React from 'react';
import { Box, Dialog, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import Header from './Header';
import { useShopStore } from '~/domains';

const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    zIndex: 100,

    '& .MuiDialog-paper': {
      width: '100%',
      height: '100%',
      margin : 0,
      borderRadius: 0,
      maxHeight: 'none',
    },
  },
  body: {
    flex: 1,
    paddingTop: '5.6rem',
  },
}));

const PageDialog: React.FC = observer(() => {
  const classes = useStyle();
  const {
    pageDialog,
    pageDialog: {
      isVisible,
      title,
      Contents,
    },
  } = useShopStore();

  if (!isVisible) return null;

  return (
    <Dialog open={isVisible} className={classes.root}>
      <Header title={title} onClickClose={pageDialog.close} />
      <Box className={classes.body}>
        { Contents && <Contents /> }
      </Box>
    </Dialog>
  );
});

export default PageDialog;
