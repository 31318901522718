import React from 'react';
import { Box, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  root: {
    ['& .MuiDialog-scrollPaper']: {
      alignItems: 'flex-end',

      ['& .MuiDialog-paperScrollPaper.MuiDialog-paperScrollPaper.MuiPaper-rounded']: {
        width: '100%',
        maxHeight: 'none',
        maxWidth: 'none',
        margin: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
  handleArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '2rem',
  },
  handleIcon: {
    width: '4rem',
    height: '0.4rem',
    backgroundColor: `${theme.palette.grey[200]}`,
    borderRadius: '4px',
  },
}));

interface BottomSheetPropTypes {
  bodyClassName: string;
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const BottomSheet: React.FC<BottomSheetPropTypes> = ({
  bodyClassName,
  isOpen,
  children,
  onClose,
}) => {
  const classes = useStyle();

  const handleClickClose = () => onClose();
  const handleClickContent = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <Dialog className={classes.root} open={isOpen} onClick={handleClickClose}>
      <Box className={bodyClassName} onClick={handleClickContent}>
        <Box className={classes.handleArea} onClick={handleClickClose}>
          <Box className={classes.handleIcon} />
        </Box>
        {children}
      </Box>
    </Dialog>
  );
};

export default BottomSheet;
