import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Remove } from '@material-ui/icons';

type CounterPropTypes = {
  width?: number;
  count?: number;
  className?: string;
  changeCount?: (isIncrease: boolean) => void;
};

const useStyle = makeStyles(theme => ({
  root: ({ width }: CounterPropTypes) => ({
    display: 'flex',
    width: width,
    height: '3.4rem',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '4px',
    alignItems: 'center',
    backgroundColor: '#fff',

    ['& button']: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      padding: `0 ${theme.spacing(0.5)}rem`,
    },
  }),
  countIcon: {
    color: theme.palette.grey[500],
  },
  countTxt: {
    flexBasis: '10.4rem',
    textAlign: 'center',

    ['& p']: {
      fontSize: '1.4rem',
    },
  },
}));

const Counter: React.FC<CounterPropTypes> = ({
  width = '10.4rem',
  count = 1,
  className = '',
  changeCount,
}) => {
  const classes = useStyle({ width });

  const handleClickIncrease = () => {
    if(changeCount) changeCount(true);
  };
  const handleClickDecrease = () => {
    if(changeCount) changeCount(false);
  };

  return (
    <Box className={`${classes.root} ${className}`}>
      <button onClick={handleClickDecrease}>
        <Remove className={classes.countIcon} />
      </button>
      <Box className={classes.countTxt}>
        <Typography>
          {count}
        </Typography>
      </Box>
      <button onClick={handleClickIncrease}>
        <Add className={classes.countIcon} />
      </button>
    </Box>
  );
};

export default Counter;
