import React, { useState } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyle = makeStyles((theme) => ({
  panelTitle: {
    color: theme.palette.grey[900],
    fontWeight: 500,
    fontSize: '1.6rem',
  },
}));

type PanelPropTypes = {
  className?: string;
  panelTitle: string;
  expanded?: boolean;
  children: React.ReactNode;
  onClickExpansion?: () => void;
};

const Panel: React.FC<PanelPropTypes> = ({
  children,
  className,
  expanded = false,
  panelTitle,
  onClickExpansion,
}) => {
  const classes = useStyle();
  const [isExpanded, setIsExpanded] = useState(expanded);
  const handleChangeExpanded = () => setIsExpanded(prev => !prev);

  return (
    <ExpansionPanel
      square
      className={className}
      expanded={isExpanded}
      onChange={handleChangeExpanded}
      onClick={onClickExpansion}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.panelTitle}>{panelTitle}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

Panel.defaultProps = {
  className: '',
  expanded: false,
};

export default Panel;
