import React, { useEffect } from 'react';
import { Snackbar, SnackbarProps, Fade, makeStyles } from '@material-ui/core';

import elevation from '~/styles/theme/elevation';

interface Style {
  width: string;
}

const useStyle = makeStyles(theme => ({
  root: ({ width }: Style) => ({
    width,
    borderRadius: '4px',
    height: '4.8rem',
    padding: `0 ${theme.spacing(2)}rem`,
    left: 'auto',
    right: 'auto',

    ['& .MuiSnackbarContent-root']: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.grey[900],
      fontSize: '1.4rem',
      color: theme.palette.grey[300],
      boxShadow: elevation['4dp'],
      borderRadius: '4px',
    },
  }),
}));

interface SnackBarPropTypes extends SnackbarProps {
  className?: string;
  text: string;
  width?: string;
  isShow: boolean;
  onClose?: () => void;
}

const SnackBar: React.FC<SnackBarPropTypes> = ({
  className = '',
  text,
  width = '100%',
  isShow,
  onClose,
  ...rest
}: SnackBarPropTypes) => {
  const classes = useStyle({ width });

  useEffect(() => {
    if(isShow) {
      setTimeout(() => {
        if (onClose) onClose();
      }, 1000);
    }
  }, [isShow]);

  return (
    <Snackbar
      {...rest}
      className={`${classes.root} ${className}`}
      open={isShow}
      message={text}
      TransitionComponent={Fade}
      onClose={onClose}
    />
  );
};

export default SnackBar;
