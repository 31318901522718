import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, makeStyles } from '@material-ui/core';

interface Style {
  standardFontSize: string;
}

const useStyle = makeStyles(() => ({
  root: ({ standardFontSize }: Style) => ({
    fontSize: standardFontSize,
    '& *': {
      all: 'revert',
    },
    '&>*:first-child': {
      marginTop: 0,
    },
    '&>*:last-child': {
      marginBottom: 0,
    },
  }),
}));

interface MarkdownPropTypes {
  source: string;
  standardFontSize?: string;
}

const Markdown: React.FC<MarkdownPropTypes> = ({
  source,
  standardFontSize = '1.6rem',
}) => {
  const classes = useStyle({ standardFontSize });

  return (
    <Box className={classes.root}>
      <ReactMarkdown
        source={source}
        escapeHtml={false}
        renderers={{
          link: 'span',
        }}
      />
    </Box>
  );
};

export default Markdown;
