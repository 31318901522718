import React from 'react';
import { TextField } from '@material-ui/core';

interface NumberFieldProp {
  value: string;
  setValue: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: boolean;
  variant?: any;
  maxLength?: number;
  className?: string;
}

const NumberField: React.FC<NumberFieldProp> = ({
  maxLength,
  setValue,
  className,
  ...rest
}) => {
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const charCode = (event.which) ? event.which : event.keyCode;
    const isNotDigitKey = (charCode === 69 || charCode === 189 || charCode === 190);
    if (isNotDigitKey) event.preventDefault();
    const isNotNumberKey = (charCode > 31 && (charCode < 48 || charCode > 57));
    if (isNotNumberKey) event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: {
        value,
      },
    } = event;
    if (maxLength && value.length > maxLength) return;
    setValue(value);
  };

  return (
    <TextField
      {...rest}
      className={className}
      inputProps={{
        type: 'number',
        pattern: '[0-9]*',
        inputMode: 'numeric',
      }}
      onKeyPress={onKeyPress}
      onChange={handleChange}
    />
  );
};

export default NumberField;
