import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import DaumPostcode from 'react-daum-postcode';

import { AddressInfo } from './interface';
import { POSTCODE, ADDRESS1 } from '~/constant';

const useStyle = makeStyles((theme) => ({
  root: ({ open }: { open: boolean }) => ({
    display: open ? 'block' : 'none',
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }),
  titleBar: {
    display: 'flex',
    alignItems: 'center',
    height: '5.6rem',
    paddingLeft: `${theme.spacing(2)}rem`,
    paddingRight: `${theme.spacing(2)}rem`,
    backgroundColor: '#fff',
  },
  title: {
    marginLeft: `${theme.spacing(2)}rem`,
    fontSize: '2rem',
    lineHeight: '1.3',
  },
  button: {
    width: '2.4rem',
    height: '2.4rem',
  },
  postCodeWrap: {
    height: 'calc(100% - 5.6rem)',
  },
}));

interface FindAddressDialogProps {
  show: boolean;
  onClose?: (value?: AddressInfo) => void;
}

interface AddressResponse {
  zonecode: string;
  address: string;
}

const getAddressInfo = (res: AddressResponse): AddressInfo => ({
  [POSTCODE]: res.zonecode,
  [ADDRESS1]: res.address,
});

const PostcodeAddressDialog: React.FC<FindAddressDialogProps> = ({
  show,
  onClose,
}) => {
  const [open, setOpen] = useState(show);
  const classes = useStyle({ open });
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  useEffect(() => {
    if (show !== open) setOpen(show);
  }, [show]);
  const onComplete = (res: AddressResponse) => {
    history.back();
    if (onClose) onClose(getAddressInfo(res));
  };
  return (
    <Box
      className={classes.root}
    >
      <Box className={classes.titleBar}>
        <button type="button" className={classes.button} onClick={handleClose}>
          <Close />
        </button>
        <Typography className={classes.title}>
          주소 검색
        </Typography>
      </Box>
      <Box className={classes.postCodeWrap}>
        <DaumPostcode
          onComplete={onComplete}
          height="100%"
        />
      </Box>
    </Box>
  );
};

export default PostcodeAddressDialog;
