import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { trackEvent } from '~/utils';
import { UpdateContentDialogDto } from '~/common';
import { useShopStore } from '~/domains';
import { PolicyTerms, PolicyPrivacy } from '~/components/policy';

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: `${theme.spacing()}rem`,
    backgroundColor: '#fff',
    paddingBottom: `${theme.spacing(3)}rem`,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '4.4rem',
    paddingLeft: `${theme.spacing(2)}rem`,
    paddingRight: `${theme.spacing(2)}rem`,
  },
  headerTxt: {
    color: theme.palette.grey[600],
    fontSize: '1.2rem',
    paddingRight: `${theme.spacing()}rem`,
    ['&:last-child']: {
      paddingRight: 0,
    },
  },
  contents: {
    padding: `${theme.spacing(2)}rem ${theme.spacing(2)}rem 0`,
  },
  contentsTxt: {
    color: theme.palette.grey[500],
    fontSize: '1.1rem',
  },
}));

const Footer = () => {
  const {
    contentDialog,
  } = useShopStore();
  const classes = useStyle();

  const handleClickTerms = () => {
    trackEvent('footer', 'terms');
    contentDialog.open({
      title: '이용약관',
      // eslint-disable-next-line
      Contents: () => (<PolicyTerms />),
    } as UpdateContentDialogDto);
  };

  const handleClickPolicy = () => {
    trackEvent('footer', 'policy');
    contentDialog.open({
      title: '개인정보처리방침',
      // eslint-disable-next-line
      Contents: () => (<PolicyPrivacy />),
    } as UpdateContentDialogDto);
  };

  const handleClickBusinessOperator = () => {
    trackEvent('footer', 'businessOperator');
  };

  return (
    <Box component="footer" className={classes.root}>
      <Box className={classes.header}>
        <Typography
          className={classes.headerTxt}
          onClick={handleClickTerms}
        >
          이용약관
        </Typography>
        <Typography
          className={classes.headerTxt}
          onClick={handleClickPolicy}
        >
          개인정보처리방침
        </Typography>
        <Typography
          component="a"
          target="_blank"
          href='http://www.ftc.go.kr/www/bizCommView.do?key=232&apv_perm_no=2018322016230200985&pageUnit=10&searchCnd=wrkr_no&searchKrwd=1138662155&pageIndex=1'
          className={classes.headerTxt}
          onClick={handleClickBusinessOperator}
        >
          사업자정보확인
        </Typography>
      </Box>
      <Divider />
      <Box className={classes.contents}>
        <Typography className={classes.contentsTxt}>
          (주)클래스팅
          <br />
          <br />
          대표이사 : 조현구<br />
          고객센터<br />
          - 홈페이지 : support.classting.com<br />
          주소 : 서울특별시 강남구 삼성로85길 26, 14층<br />
          대표 번호 : 1544-0570<br />
          통신판매업신고번호 : 2018-서울강남-00985<br />
          사업자등록번호 : 113-86-62155<br />
          <br />
          (주)클래스팅은 통신판매중개사로서 통신판매의 당사자이며, 광고, 상품 주문, 배송, 환불 및 민원의 의무와 책임은 “(주)클래스팅“에 있습니다.<br />
          <br />
          (c) Classting 2019 All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
