import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useShopStore } from '~/domains';
import themeColor from '~/styles/theme/color';

const useStyle = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      width: `calc(100% - ${theme.spacing(4)}rem)`,
      margin: 0,
    },
  },
  dialogWrap: {
    padding: `${theme.spacing(2.5)}rem ${theme.spacing(2.5)}rem ${theme.spacing(2)}rem`,
  },
  text: {
    fontSize: '1.5rem',
    lineHeight: 1.53,
    color: themeColor.grey[800],
    whiteSpace: 'pre-wrap',
  },
  buttonWrap: {
    paddingTop: `${theme.spacing(2)}rem`,
    textAlign: 'right',
  },
  cancelButton: {
    color: themeColor.grey[600],
    marginRight: `${theme.spacing(2)}rem`,
  },
  button: {
    padding: `${theme.spacing()}rem ${theme.spacing(0.625)}rem`,
    lineHeight: 1.5,
    fontSize: '1.4rem',
    display: 'inline-block',
    cursor: 'pointer',
  },
}));

const AlertDialog: React.FC = observer(() => {
  const {
    alertDialog: {
      isVisible,
      dialog: {
        text,
        cancelButtonEnabled,
      },
      confirm,
      cancel,
    },
  } = useShopStore();
  const classes = useStyle();

  const handleClickBoxArea = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
  };

  if (!isVisible) return null;
  return (
    <Dialog className={classes.root} open={isVisible}>
      <Box className={classes.dialogWrap} onClick={handleClickBoxArea}>
        <Typography className={classes.text}>{text}</Typography>
        <Box className={classes.buttonWrap}>
          {cancelButtonEnabled && (
            <Typography
              className={`${classes.button} ${classes.cancelButton}`}
              onClick={cancel}
            >
              취소
            </Typography>
          )}
          <Typography
            className={classes.button}
            color="primary"
            onClick={confirm}
          >
            확인
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
});

export default AlertDialog;
