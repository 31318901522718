import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';

import Topbar from './Topbar';
import Footer from './Footer';
import {
  AlertDialog,
  ContentDialog,
  PageDialog,
} from '~/unit';

interface Style {
  pageSpacing: number | number[];
}

const useStyle = makeStyles((theme) => ({
  root: {
    paddingTop: `${theme.spacing(7)}rem`,
  },
  pageWrap: ({ pageSpacing = 0 }: Style) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 5.6rem)',
    padding: typeof pageSpacing === 'number'
      ? `${theme.spacing(pageSpacing)}rem`
      : pageSpacing.map((val) => `${theme.spacing(val)}rem`).join(' '),
  }),
  pageChildren: {
    flex: 1,
  },
}));

interface LayoutPropTypes {
  children: React.ReactNode;
  title: string;
  ButtonIcon?: React.ComponentType;
  pageSpacing?: number | number[];
  hideFooter?: boolean;
  trackGoBackEvent?: () => void;
  trackCloseEvent?: () => void;
}

const Layout: React.FC<LayoutPropTypes> = ({
  children,
  title,
  ButtonIcon,
  pageSpacing = 0,
  hideFooter = false,
  trackGoBackEvent,
  trackCloseEvent,
}: LayoutPropTypes) => {
  const classes = useStyle({ pageSpacing });

  return (
    <Container className={classes.root}>
      <Topbar
        title={title}
        ButtonIcon={ButtonIcon}
        trackGoBackEvent={trackGoBackEvent}
        trackCloseEvent={trackCloseEvent}
      />
      <Box className={classes.pageWrap}>
        <Box className={classes.pageChildren}>
          {children}
        </Box>
        {!hideFooter && (<Footer />)}
      </Box>
      <AlertDialog />
      <ContentDialog />
      <PageDialog />
    </Container>
  );
};


export default Layout;
