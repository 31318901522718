import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyle = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    minHeight: '5.6rem',
    background: '#fff',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2)}rem`,
    zIndex: 100,
  },
  closeButton: {
    marginRight: `${theme.spacing(2)}rem`,
  },
  title: {
    fontSize: '2rem',
    lineHeight: 1.3,
    color: theme.palette.grey[900],
  },
}));

interface HeaderPropTypes {
  title: string;
  onClickClose: () => void;
}

const Header: React.FC<HeaderPropTypes> = ({ title, onClickClose }) => {
  const classes = useStyle();

  return (
    <Box component="header" className={classes.root}>
      <button className={classes.closeButton} onClick={onClickClose}>
        <Close />
      </button>
      <Typography className={classes.title}>{title}</Typography>
    </Box>
  );
};

export default Header;
