import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { useShopStore } from '~/domains';
import Markdown from '~/unit/Markdown';

const PolicyPrivacy: React.FC = observer(() => {
  const {
    policyStore: {
      items,
      loadClasstingPolicy,
    },
  } = useShopStore();

  const policy = items.get('privacy');

  useEffect(() => {
    if (!policy) {
      loadClasstingPolicy('privacy');
    }
  }, []);

  if (!policy) return null;

  const content = policy.formatted;

  return (
    <Markdown source={content} standardFontSize="0.8rem" />
  );
});

export default PolicyPrivacy;
