import React from 'react';
import { Box, Button as MuiButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import themeColor from '~/styles/theme/color';

type ButtonPropTypes = {
  width?: string;
  height?: string;
  color?: string;
  backgroundColor?: string;
  text?: string;
  disabled?: boolean;
  onClick?: () => void;
};

const useStyle = makeStyles((theme) => ({
  root: ({ width, height, backgroundColor }: ButtonPropTypes) => ({
    width,
    height,
    backgroundColor,
  }),
  button: ({ color }: ButtonPropTypes) => ({
    color,
    width: '100%',
    height: '100%',
    fontSize: '1.4rem',
    padding: `0 ${theme.spacing(2)}rem`,
  }),
}));

const Button: React.FC<ButtonPropTypes> = ({
  width = '100%',
  height = '4.8rem',
  color = '#fff',
  backgroundColor = themeColor.green[600],
  text,
  ...rest
}) => {
  const classes = useStyle({ width, height, color, backgroundColor });
  return (
    <Box className={classes.root}>
      <MuiButton {...rest} className={classes.button}>
        {text}
      </MuiButton>
    </Box>
  );
};

export default Button;
