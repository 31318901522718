import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';

import { useShopStore } from '~/domains';

const useStyle = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    alignItems: 'center',
    backgroundColor: '#fff',
    display: 'flex',
    height: `${theme.spacing(7)}rem`,
    paddingLeft: `${theme.spacing(2)}rem`,
    paddingRight: `${theme.spacing(2)}rem`,
    zIndex: 10,
  },
  titleWrap: {
    width: '100%',
    marginLeft: `${theme.spacing(2)}rem`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    flex: 1,
    fontSize: '2rem',
    lineHeight: '1.3',
  },
  button: {
    width: '2.4rem',
    height: '2.4rem',
  },
}));

type TopbarProps = {
  title: string;
  ButtonIcon?: React.ComponentType;
  trackGoBackEvent?: () => void;
  trackCloseEvent?: () => void;
};

const Topbar: React.FC<TopbarProps> = ({
  title,
  ButtonIcon = ArrowBack,
  trackGoBackEvent,
  trackCloseEvent,
}: TopbarProps) => {
  const {
    view: {
      goBack,
      exitWeb,
    },
  } = useShopStore();
  const classes = useStyle();

  const handleClickGoBack = () => {
    if(typeof trackGoBackEvent === 'function') trackGoBackEvent();
    goBack();
  };

  const handleClickClose = () => {
    if(typeof trackCloseEvent === 'function') trackCloseEvent();
    exitWeb();
  };

  return (
    <Box component="header" className={classes.root}>
      <button className={classes.button} onClick={handleClickGoBack}>
        <ButtonIcon />
      </button>
      <Box className={classes.titleWrap}>
        <Typography component="h1" className={classes.title}>
          {title}
        </Typography>
        <button className={classes.button} onClick={handleClickClose}>
          <Close />
        </button>
      </Box>
    </Box>
  );
};

export default Topbar;
